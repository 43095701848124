// Scripts injected with innerHtml will not be run by in browser for security reasons.
// Because of this React rerenders would cause scripts to not be executed.
// This is a temporary script executing all inline scripts. Ideally we
// should leave this to Cookiebot.
export function evaluateInlineScript(domSelector: string): void {
  const container = document.querySelector(domSelector);

  if (container === null) {
    return;
  }

  const scriptTags = container.querySelectorAll("script");
  for (const scriptTag of scriptTags) {
    if (scriptTag.type === "text/plain") {
      return;
    }

    const script = document.createElement("script");
    const scriptText = scriptTag.innerHTML;

    if (scriptText) {
      script.append(document.createTextNode(scriptText));
      script.remove();
    }

    if (!scriptText && scriptTag.src) {
      const newScript = document.createElement("script");
      newScript.src = scriptTag.src;
      newScript.async = false;
      document.body.append(newScript);
    }
  }
}
